<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- ========== Hero Section ========== -->
    <div class="position-relative overflow-hidden bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-trust-hero.svg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <!-- <a href="/solutions/api-management"> -->
                  <router-link :to="{ path: '/solutions/api-management' }">
                    API Management
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Kong Enterprise API Solution</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- End Breadcrumb -->
      <div class="container position-relative z-index-2 space-top-1 space-bottom-3">
        <div class="w-100 w-md-80 w-lg-75 mx-auto text-center">
          <span class="d-block small font-weight-bold text-cap mb-4">Kong Enterprise API Solution</span>
          <div class="mb-4">
            <img class="max-w-23rem" src="@/assets/svg/logos/logo.svg"> <br class="d-sm-none"> <span class="small font-weight-bold mx-2">x</span> <br class="d-sm-none"> <img class="max-w-19rem" src="@/assets/img/partners/kong-color.svg">
          </div>
          <p>beNovelty has been partnering with Kong since 2019 and was recognised as Kong Gold Partner from 2019 to 2021. beNovelty has supported number of innovative enterprises in Hong Kong, Asia and Europe to implement Kong API solution deployment in scale.</p>
        </div>
      </div>
      <!-- SVG Background -->
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
      <!-- End SVG Background -->
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Slogan Section ========== -->
    <div class="container position-relative z-index-2">
      <div class="card mt-n11 w-lg-80 mx-auto" data-aos="fade-up">
        <div class="card-body py-7">
          <figure class="mx-auto text-center mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="32px"
              height="32px"
              viewBox="0 0 8 8"
              style="enable-background:new 0 0 8 8;"
              xml:space="preserve"
            >
              <path
                fill="#E7EAF3"
                d="M3,1.3C2,1.7,1.2,2.7,1.2,3.6c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5
        C1.4,6.9,1,6.6,0.7,6.1C0.4,5.6,0.3,4.9,0.3,4.5c0-1.6,0.8-2.9,2.5-3.7L3,1.3z M7.1,1.3c-1,0.4-1.8,1.4-1.8,2.3
        c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5c-0.7,0-1.1-0.3-1.4-0.8
        C4.4,5.6,4.4,4.9,4.4,4.5c0-1.6,0.8-2.9,2.5-3.7L7.1,1.3z"
              />
            </svg>
          </figure>
          <div class="text-center">
            <blockquote class="lead">Kong Once Again Named a Leader in the 2021 Gartner® Magic Quadrant™ for Full Lifecycle API Management</blockquote>
            <a id="ga-solution-kongapisolution-kong-blog-button" class="font-weight-bold" href="https://konghq.com/blog/leader-2021-gartner-mq-api-management/" target="_blank">Learn More<i class="fas fa-angle-right fa-sm ml-2" /></a>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Slogan Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">About</span>
        <h2>What is Kong API Gateway?</h2>
      </div>
      <div class="row">
        <div class="col-md-4 mb-5 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-3.svg" alt="Sub-millisecond performance">
            </figure>
            <h3>Sub-Millisecond Performance</h3>
            <p>Looking for <span class="font-weight-bold">sub-millisecond processing latency</span> to support thousands of transactions per second? The lightweight Kong Gateway core has you covered.</p>
          </div>
        </div>
        <div class="col-md-4 mb-5 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-25.svg" alt="Universal deployment">
            </figure>
            <h3>Universal Deployment</h3>
            <p>Kong Gateway <span class="font-weight-bold">supports hybrid or multi-cloud infrastructure</span>, and includes a Kubernetes-native ingress solution and support for declarative configuration management.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-29.svg" alt="Unlimited extensibility">
            </figure>
            <h3>Unlimited Extensibility</h3>
            <p>Need <span class="font-weight-bold">more functionality to integrate</span> with your IdP, add an API key to a service or simply transform requests before they hit your server? There’s a plugin for that.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== Advantage Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <div class="w-md-75 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">advantages</span>
          <h2>Accelerate Your Microservices Journey with the World’s Most Popular API Gateway</h2>
        </div>

        <div class="row align-items-lg-center mb-9">
          <div class="col-md-6 order-md-1" data-aos="fade">
            <div class="position-relative h-100 w-80 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/kong-building.svg">
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="mb-3">Focus on Building, Not Operations</h3>
            <p>Kong Gateway is part of the Konnect managed connectivity platform. Konnect delivers connectivity functionality such as API Portals and AI-based anomaly detection, while providing the flexibility of running high performance connectivity runtimes.</p>
          </div>
        </div>

        <div class="row align-items-lg-center mb-9">
          <div class="col-md-6" data-aos="fade">
            <div class="position-relative h-100 w-80 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/kong-plugins.svg">
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="mb-3">Expand Capabilities with Plugins</h3>
            <p>Use one of the many plugins developed by Kong or our community to add the functionality you need. Can’t find what you need? Build your own plugin with our built in, well-documented plugin development kit.</p>
          </div>
        </div>

        <div class="row align-items-lg-center mb-9">
          <div class="col-md-6 order-md-1" data-aos="fade">
            <div class="position-relative h-100 w-80 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/kong-apiops.svg">
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="mb-3">Be an APIOps Superhero</h3>
            <p>Configure Gateway natively using an API, web UI, or with declarative configuration to manage updates via your CI/CD pipelines.</p>
          </div>
        </div>

        <div class="row align-items-lg-center mb-9">
          <div class="col-md-6" data-aos="fade">
            <div class="position-relative h-100 w-80 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/kong-environments.svg">
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="mb-3">Support for Multiple Operating Environments</h3>
            <p>We have packages that support management within Docker containers or traditional servers like Debian and RedHat.</p>
          </div>
        </div>

        <div class="row align-items-lg-center">
          <div class="col-md-6 order-md-1" data-aos="fade">
            <div class="position-relative h-100 w-80 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/kong-kubernetes.svg">
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="mb-3">Run Natively on Kubernetes</h3>
            <p>Our Kubernetes Ingress controller uses native CRDs that allow users to declaratively configure every aspect of Gateway from plugins, health checks, load balancing and more.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ==========  CTA Section ==========  -->
    <div class="container space-2 space-lg-3">
      <div class="text-center cta-bg" data-aos="fade-up">
        <div class="w-lg-60 mx-auto">
          <h2 class="mb-3">Want to Build Your Own Enterprise API Platform &amp; Gateway?</h2>
          <p class="lead">Talk to us and discover more opportunities.</p>
          <span class="d-block mt-5">
            <!-- <a id="ga-solution-kongapisolution-contact-us-button" href="/contact" class="btn btn-primary"> -->
            <router-link id="ga-solution-kongapisolution-contact-us-button" class="btn btn-primary" :to="{ path: '/contact' }">
              Talk to Our API Consultant Now
            </router-link>
            <!-- </a> -->
          </span>
        </div>
      </div>
    </div>
    <!-- ========== End CTA Section ==========  -->

    <!-- ========== More Solutions Section ==========-->
    <div
      class="space-2 space-lg-3 bg-blue-light"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')',
               backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">More by beNovelty</span>
        <h2>Want to Know More About API Economy?</h2>
      </div>
      <div class="position-relative">
        <div class="container">
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-3"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;infinite&quot;: true,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-pagination d-lg-none&quot;,
                &quot;autoplay&quot;: true,
                &quot;autoplaySpeed&quot;: 5000,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-4.svg">
                  <h4>API Consultancy</h4>
                  <p>Read more to find out what makes beNovelty different in API consultancy.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-consultancy-button" href="/solutions/api-consultancy"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-consultancy-button" :to="{ path: '/solutions/api-consultancy' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-28.svg">
                  <h4>API Product</h4>
                  <p>Find out the 3 simple steps for building API-as-a-Products.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-product-button" href="/solutions/api-product"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-product-button" :to="{ path: '/solutions/api-product' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-3.svg">
                  <h4>API Management</h4>
                  <p>Learn how to manage, secure, mediate, analyze complex enterprise API programme with API Management.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-management-button" href="/solutions/api-management"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-management-button" :to="{ path: '/solutions/api-management' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-69.svg">
                  <h4>API Community</h4>
                  <p>Discover more on our latest API community events.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-community-button" href="/solutions/api-community"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-community-button" :to="{ path: '/solutions/api-community' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Solutions Section ========== -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
export default {
  name: 'KongEnterpriseApiSolution',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  created() {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Kong Enterprise API Solution | Solutions | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/solutions/api-management/kong-enterprise-api-solution' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Kong Enterprise API Solution | Solutions | beNovelty' },
        { property: 'og:description', content: 'beNovelty is the top Kong Gold Partner in Hong Kong to deliver Kong Enterprise API Solution. Kong API Gateway is the world’s most popular open-source API management gateway—built for hybrid and multi-cloud, optimized for microservices and distributed architectures.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/solutions/api-management/kong-enterprise-api-solution' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
